import React from 'react';

const SearchIconSvg = () => (
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Search icon"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5404 18.8028C18.6351 17.0625 20.5438 12.3323 18.8036 8.23759C17.0634 4.14283 12.3332 2.23411 8.23843 3.97434C4.14367 5.71457 2.23495 10.4448 3.97518 14.5395C5.71541 18.6343 10.4456 20.543 14.5404 18.8028Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.0853 17.0844L23.3333 23.3333"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SearchIconSvg;
