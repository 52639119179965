import React from 'react';

import ArticleThumbnail from '../../components/ArticleThumbnail/ArticleThumbnail';
import SearchInput from '../../components/SearchInput/SearchInput';
import Heading from '../../components/Typography/Heading/Heading';
import { cn } from '../../utils';
import * as styles from './ArticleThumbnails.module.scss';
import IArticleThumbnails from './ArticleThumbnails.type';

const ArticleThumbnails = ({
    posts,
    className,
    search,
    onSearchSubmit,
    searchString,
    isPress
}: IArticleThumbnails) => {
    return (
        <section
            className={cn(
                styles.element,
                className,
                search && styles.withSearch
            )}
        >
            {search && onSearchSubmit && (
                <div className={cn("container", styles.searchContainer)}>
                    <SearchInput onSubmit={onSearchSubmit} />
                </div>
            )}
            <div className={cn("container", styles.articles)}>
                {posts.map((post) => (
                    <ArticleThumbnail isPress={isPress} key={post.title} {...post} />
                ))}
                {search && searchString !== "" && posts.length === 0 && (
                    <Heading
                        level="h3"
                        className={styles.noSearchResults}
                    >{`No results for "${searchString}"`}</Heading>
                )}
            </div>
        </section>
    );
};

export default ArticleThumbnails;
