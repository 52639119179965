import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import SearchIconSvg from './SearchIconSvg/SearchIconSvg';
import * as styles from './SearchInput.module.scss';
import ISearchInput from './SearchInput.type';

const SearchInput = ({ onSubmit }: ISearchInput) => {
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    return (
        <Formik
            initialValues={{ search: "" }}
            onSubmit={(values) => {
                onSubmit(values.search);
            }}
            validate={(values) => {
                timeoutId && clearTimeout(timeoutId);
                setTimeoutId(setTimeout(() => onSubmit(values.search), 1000));
            }}
        >
            <Form className={styles.element}>
                <SearchIconSvg />
                <Field name="search" placeholder="Search" />
            </Form>
        </Formik>
    );
};

export default SearchInput;
