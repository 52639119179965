// extracted by mini-css-extract-plugin
export var category = "ArticleThumbnail-module--category--be856";
export var container = "ArticleThumbnail-module--container--d8872";
export var content = "ArticleThumbnail-module--content--ed1f9";
export var element = "ArticleThumbnail-module--element--7c588";
export var excerpt = "ArticleThumbnail-module--excerpt--71492";
export var excerptBg = "ArticleThumbnail-module--excerpt-bg--7f62d";
export var info = "ArticleThumbnail-module--info--851fd";
export var isPress = "ArticleThumbnail-module--isPress--94eab";
export var publicationName = "ArticleThumbnail-module--publicationName--77a74";
export var thumbnail = "ArticleThumbnail-module--thumbnail--db517";