import React from "react";

import Button from "../../components/Button/Button";
import TitleUnderlineParagraph from "../../components/TitleUnderlineParagraph/TitleUnderlineParagraph";
import { cn } from "../../utils";
import * as styles from "./Hero.module.scss";
import IHero from "./Hero.type";

const Hero = ({
    button,
    descriptionWidth,
    align = "left",
    ...props
}: IHero) => {
    return (
        <section className={styles.section}>
            <div className={cn("container", styles.container)}>
                <TitleUnderlineParagraph
                    className={cn(
                        styles.title,
                        descriptionWidth === "small" && styles.smallDescription
                    )}
                    {...props}
                    heading={props.title}
                    headingLevel="h1"
                    textSize="large"
                    underline="center"
                    align={align}
                />
                {button && (
                    <Button
                        className={styles.button}
                        type="large"
                        href={button.url}
                    >
                        {button.title}
                    </Button>
                )}
            </div>
        </section>
    );
};

export default Hero;
