import { Link } from "gatsby";
import React from "react";

import { cn, stripPTags } from "../../utils";
import Image from "../Image/Image";
import TitleUnderlineParagraph from "../TitleUnderlineParagraph/TitleUnderlineParagraph";
import Paragraph from "../Typography/Paragraph/Paragraph";
import * as styles from "./ArticleThumbnail.module.scss";
import IArticleThumbnail from "./ArticleThumbnail.type";

const ArticleThumbnail = (props: IArticleThumbnail) => {
    const dateFormatted =
        props.date &&
        new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "2-digit",
            year: "numeric",
        }).format(new Date(props.date));

    const {
        name: categoryName,
        slug: categorySlug,
    } = props.categories.nodes[0];

    const postAuthor = categorySlug !== "press" && props.author.node.name;

    return (
        <article>
            <Link
                to={"/" + categorySlug + props.uri}
                className={styles.element}
            >
                {props.featuredImage && (
                    <div className={cn(styles.thumbnail, props.isPress && styles['isPress'])}>
                        <Image {...props.featuredImage.node} sizes="400px" />
                        <div className={styles.excerptBg}>
                            <Paragraph size="xsmall" className={styles.excerpt}>
                                {props.excerpt && stripPTags(props.excerpt)}
                            </Paragraph>
                        </div>
                    </div>
                )}
                <div className={styles.content}>
                    <span className={cn("text-xsmall", styles.category)}>
                        {categoryName}
                    </span>
                    <TitleUnderlineParagraph
                        className={styles.info}
                        heading={props.title}
                        headingLevel="h4"
                        underline="left"
                        text={
                            postAuthor
                                ? `${postAuthor}<br><span>${dateFormatted}</span>`
                                : `<span>${dateFormatted}</span>`
                        }
                        textSize="xsmall"
                    />
                    {props.blogPost?.publicationName && (
                        <Paragraph
                            size="xsmall"
                            className={cn(
                                styles.publicationLink,
                                styles.mobileAlignCenter,
                                styles.publicationName
                            )}
                        >
                            {props.blogPost.publicationName}
                        </Paragraph>
                    )}
                </div>
            </Link>
        </article>
    );
};

export default ArticleThumbnail;
